.cookiesPolicyContainer {
  margin: 5% auto 2% auto;
  width: 80%;
  flex-grow: 1;
  color: var(--silver);
}

.padded {
  padding-left: 1%;
}

.cookiesPolicyTable {
  border: 1px var(--silver) solid;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.cookiesPolicyTableTd {
  border: 1px var(--silver) solid;
  padding: 1rem;
  margin: 0px;
}

.cookiesPolicyTableTr {
  margin-top: 10px;
  border: 1px var(--silver) solid;
}

.cookiesPolicyTableHeader {
  margin-bottom: 1rem;
  font-weight: bold;
}

ol, ul {
  margin-left: 2rem;
}

.cookiesPolicyTitle {
  font-weight: bold;
}
